import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import App from './App';
// import Responses from './Responses'
import theme from './theme'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

// const Responses = () => {
//     return (
//         <h1>Hello world</h1>
//     )
// }

const Home = () => {
    return (
        <Router>
            <Routes>
                {/*<Route path="/responses" element={<Responses/>}/>*/}
                <Route path="*" element={<App/>}/>
            </Routes>
        </Router>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <Home/>
    </ChakraProvider>
);