// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools';
// 2. Update the breakpoints as key-value pairs
export const breakpoints = createBreakpoints({
    sm: '420px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
});


// 2. Add your color mode config
const config = {
    initialColorMode: 'dark',
    useSystemColorMode: true,
}

// 3. extend the theme
const theme = extendTheme({ config,breakpoints })

export default theme