import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputField,
    NumberInputStepper,
    // Textarea,
} from '@chakra-ui/react'
// import { Radio, RadioGroup } from '@chakra-ui/react';
import {useState, useRef, useLayoutEffect, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
    Center,
    // Stack,
    Heading,
    Box,
    Text,
    // Slider,
    // SliderTrack,
    // SliderFilledTrack,
    // SliderThumb,
    // SliderMark,
    Button,
    SkeletonText,
    SimpleGrid,
    Select,
    NumberInput,
    // Divider,
    FormControl,
    FormLabel,
    useToast,
    useColorMode,
    useColorModeValue,
    // Editable,
    // EditableInput,
    // EditableTextarea,
    // EditablePreview,
    // Input
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import {ArrowForwardIcon} from "@chakra-ui/icons";


function EditableControls() {
    return null;
}

function App() {
    // const [prompt, setPrompt] = useState('');
    const [temp, setTemp] = useState(0.3);
    const [age, setAge] = useState(10);
    const [gender, setGender] = useState('');
    const [weight, setWeight] = useState(0);
    const [target, setTarget] = useState(0);
    const [week, setWeek] = useState(1);
    const [equipment, setEquipment] = useState("");
    const [goal, setGoal] = useState("");
    const [days, setDays] = useState(1);
    const [minutes, setMinutes] =useState(10);
    const [response, setResp] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [model, setModel] = useState('Engine-1'); //
    const navigate = useNavigate();
    const myDivRef = useRef(null);
    const toast = useToast();
    const [tempval, setTempVal] = useState(0.3);
    const { colorMode, toggleColorMode } = useColorMode('dark');
    const bg = useColorModeValue("white", "gray.800");
    const [prompt, setPrompt] = useState("");
    // const btn = useColorModeValue()
    const goals = [
        "Aerobic fitness",
        "Rehab",
        "Core stability",
        "Increase Energy",
        "Lose Weight",
        "Ab Toning",
        "Muscle size",
        "Muscle tone",
        "Muscular Strength",
        "Sports Specific",
        "General health",
        "Body Fat Reduction",
        "Increased Flexibility",
        "Injury Rehabilitation",
        "Stay Active/Healthy",
        "Reduce Stress"
    ]

    const generatePrompt = () => {
        setPrompt(`As a certified fitness trainer (Cert 4) with 15 years of experience, provide a workout routine. My goal is ${goal}. I am ${age} years ${gender} with current weight ${weight} kg and my goal is to get to ${target} kg in ${week} weeks. I have access to ${equipment}. The workout should be ${minutes} mins for ${days} days with 8 exercises. The exercise should have a full name, a description for the exercise, instructions to do the exercise as well. The exercises to be listed as a table with the Sets, Reps, and Rest time as columns. Do not prescribe exercises that do not have a description. It should say the name of the item for example Exercise Name: "Machine Chest Press" | Exercise Description: "Take the machine..." | Sets: "4" | Reps: "12"| Rest Time: "60 Seconds in between\n provide a unique workout for each day`);
        // const prmpt = "As a certified fitness trainer (Cert 4) with 15 years of experience, provide a workout routine. My goal is to lose weight. I am 45 years Male with current weight 80 kg                   and my goal is to get to 70 kg in 10 weeks. I have access to dumbbell. The workout should be for 60 mins with 8 exercises. The exercise should have a full name, a description for the exercise, instructions to do the exercise as well. The exercises to be listed as a table with the Sets, Reps, and Rest time as columns. Do not prescribe exercises that do not have a description. It should say the name of the item for example Exercise Name: \"Machine Chest Press\" | Exercise Description: \"Take the machine...\" | Sets: \"4\" | Reps: \"12\"| Rest Time: \"60 Seconds in between sets\". Provide 3 unique workouts."

    }

    useEffect(() => {
        generatePrompt();
        // console.log(prompt)
    }, [age, weight, week, target, equipment, temp, model, gender, goal, days, minutes])
    const queryPrompt = () =>  {
        generatePrompt();
        if(age!==0 && gender !== "" && weight !==0 && target !== 0 && week!== 0 && equipment !== ""){
            // generatePrompt();
            // console.log(prompt)
            setLoading(true);
            axios.post("https://greenedesk-workouts.onrender.com/prompt",
                {
                    temperature: temp,
                    prompt: prompt,
                    model: model
                }, ).then(resp => {
                // myDivRef.current?.scrollIntoView({block: "nearest", behavior: "smooth"})
                setResp(resp.data.data)
                setLoading(false);
                toast({
                    title: "Success",
                    description: "Response Generated",
                    variant: 'left-accent',
                    position:  'top',
                    size: 'xl',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })


            }).catch(err => {
                toast({
                    title: "Timeout",
                    description: err.message,
                    variant: 'left-accent',
                    position:  'top',
                    size: 'xl',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                setLoading(false);
            });
        }
    };
    // const handleSubmit = () => {
    //     setLoading(!isLoading);
    //     axios.post("https://greenedesk-workouts.onrender.com/submit",
    //         {
    //             temperature: temp,
    //             prompt: prompt,
    //             model: model,
    //             response: response
    //         }, ).then(resp => {
    //         setLoading(false);
    //         toast({
    //             title: "Submitted",
    //             description: "Data submitted to the database",
    //             variant: 'left-accent',
    //             position:  'top',
    //             size: 'xl',
    //             status: 'success',
    //             duration: 3000,
    //             isClosable: true,
    //         })
    //     }).catch(err => {
    //         toast({
    //             title: "Timeout",
    //             description: err.message,
    //             variant: 'left-accent',
    //             position:  'top',
    //             size: 'xl',
    //             status: 'error',
    //             duration: 3000,
    //             isClosable: true,
    //         })
    //         setLoading(false);
    //     });
    // }
    useLayoutEffect(() => {
        if (window.location.hash === "#scrollToCenter") {
            myDivRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);
    return (
        <Box backgroundColor={bg}>
                {/*<Button style={{*/}
                {/*    position: "absolute",*/}
                {/*    top: 0,*/}
                {/*    right: 0,*/}
                {/*    marginRight: 20,*/}
                {/*    marginTop: 10*/}
                {/*}} onClick={() => {*/}
                {/*    navigate("/responses");*/}
                {/*}*/}
                {/*} rightIcon={<ArrowForwardIcon/>} >Responses</Button>*/}
            <Center mt={8}>
                <Heading fontSize={["xl","2xl", "3xl", "4xl"]}>GreeneDesk AI Workouts</Heading>
            </Center>
            <Center marginY={4}>
                <Box shadow='md' width={[400, 500, 800]}  padding={4} borderWidth="2px" borderRadius={8}>
                    <FormControl>
                        {/*<Center mt={7} mb={3}><Heading fontSize="xl">General Info</Heading></Center>*/}
                        <SimpleGrid rowGap={14} padding={4} columns={[1, 1, 3]} spacing={[2, 2, 6, 6, 6]}>

                                {/*<Box>*/}
                                {/*    <Text fontWeight="bold" mb='8px'>Name: </Text>*/}
                                {/*    <Input variant="filled" placeholder="Enter your name" label="Age" htmlSize={12} width='auto' ></Input>*/}
                                {/*</Box>*/}
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Goal</FormLabel>
                                <Select isDisabled={isLoading} onChange={eq => setGoal(eq.target.value)} isRequired={true} variant="filled" width="60%" placeholder='Select option'>
                                    {
                                        goals.map((gl) => {
                                            return (<option value={gl}>{gl}</option>)
                                        })
                                    }
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Current Weight (Kg) </FormLabel>
                                <NumberInput isDisabled={isLoading} onChange={num => setWeight(num)} isRequired={true} variant="filled"  min={10} max={100}  size='md' maxW={24}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Target Weight (Kg)</FormLabel>
                                <NumberInput isDisabled={isLoading} onChange={num => setTarget(num)} variant="filled"  min={10} max={100}  size='md' maxW={24} >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Weeks</FormLabel>
                                <NumberInput isDisabled={isLoading} onChange={num => setWeek(num)} variant="filled"  min={1} max={54}  size='md' maxW={24} >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Days per week</FormLabel>
                                <Select isDisabled={isLoading} onChange={eq => setDays(eq.target.value)} isRequired={true} variant="filled" width="60%" placeholder='Select option'>
                                    <option value="1">1 Day</option>
                                    <option value="2">2 Days</option>
                                    <option value="3">3 Days</option>
                                    <option value="4">4 Days</option>
                                    <option value="5">5 Days</option>
                                    <option value="6">6 Days</option>
                                    <option value="7">7 Days</option>
                                </Select>
                            </Box>

                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Minutes per day </FormLabel>
                                <NumberInput step={5} isDisabled={isLoading} onChange={num => setMinutes(num)} isRequired={true} variant="filled"  min={10} max={100}  size='md' maxW={24} >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>


                            <Box>
                                <FormLabel  fontWeight="bold" mb='8px'>Age</FormLabel>
                                <NumberInput isDisabled={isLoading} onChange={num => setAge(num)} isRequired={true} variant="filled" min={10} max={80} label="Age"  size='md' maxW={24} >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Gender</FormLabel>
                                <Select isDisabled={isLoading} onChange={gen => {
                                    setGender(gen.target.value);
                                }} isRequired={true} variant="filled"  width="50%" placeholder='Select option'>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                    <option value='other'>Other</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel fontWeight="bold" mb='8px'>Equipment</FormLabel>
                                <Select isDisabled={isLoading} onChange={eq => setEquipment(eq.target.value)} isRequired={true} variant="filled" width="60%" placeholder='Select option'>
                                    <option value='gym'>I have access to Gym</option>
                                    <option value='dumbbell'>Only Dumbbell</option>
                                    <option value='no equipments'>No Equipment</option>
                                </Select>
                            </Box>
                        </SimpleGrid>

                        {/*<Box borderRadius={15} borderWidth="2px" padding={5}>*/}
                            {/*<Editable*/}
                        {/*    // textAlign='center'*/}
                        {/*//     defaultValue={prompt}*/}
                        {/*//     fontSize='lg'*/}
                        {/*//     isPreviewFocusable={false}*/}
                        {/*// >*/}
                        {/*//     <EditablePreview>*/}
                        {/*<Center margin={10}><Button onClick={() => generatePrompt()} >Generate Prompt</Button></Center>*/}

                        {/*        <Textarea spellCheck={true} onChange={(e) => setPrompt(e.target.value)} fontSize="lg" mt={4} variant="filled" minH="230px" value={prompt}/>*/}
                        {/*        <EditableControls></EditableControls>*/}
                        {/*    </EditablePreview>*/}
                        {/*</Editable>*/}
                        {/*</Box>*/}
                        {/*<Center margin={10}><Button isLoading={isLoading} mb={4} width="xl" onClick={() => queryPrompt()}*/}
                        {/*>Generate Response</Button></Center>*/}
                        {/*<Divider orientation="horizontal"></Divider>*/}
                        {/*<Center><Heading mt={6} fontSize='xl'>Model Parameters</Heading></Center>*/}
                        {/*<Stack direction="row" padding={4} mt={8}  columns={[1, null, 2]} justify="space-around" spacing={6}>*/}
                        {/*    <Box>*/}
                        {/*        <Text fontWeight="bold" mb='8px'>Model: </Text>*/}
                        {/*        <Select isDisabled={isLoading} onChange={mod => {*/}
                        {/*            mod.target.value !== "" ? setModel(mod.target.value) : setModel("gpt-3.5-turbo")*/}
                        {/*        }} defaultValue="gpt-3.5-turbo" variant="filled"  size="lg" placeholder='Select model'>*/}
                        {/*            <option value='gpt-3.5-turbo'>GPT-3.5-turbo</option>*/}
                        {/*            <option value='gpt-3.5-turbo-0301'>GPT-3.5-turbo-0301</option>*/}
                        {/*            <option value='text-davinci-003'>Text-DaVinci-003</option>*/}
                        {/*            <option value='text-davinci-002'>Text-DaVinci-002</option>*/}
                        {/*            /!*<option value='code-davinci-002'>Code-DaVinci-002</option>*!/*/}
                        {/*            /!*, gpt-3.5-turbo-0301*!/*/}
                        {/*        </Select>*/}
                        {/*    </Box>*/}
                        {/*    <Box align="space-between">*/}
                        {/*        <Text  fontWeight="bold" mb='8px'>Temperature: </Text>*/}
                        {/*        <Slider isDisabled={isLoading} onChangeEnd={val => setTemp(val)} w={200} mt={8} defaultValue={0.3} min={0} max={1} step={0.1} onChange={e => setTempVal(e)} >*/}
                        {/*            <SliderMark*/}
                        {/*                value={tempval}*/}
                        {/*                textAlign='center'*/}
                        {/*                bg='blue.500'*/}
                        {/*                color='white'*/}
                        {/*                mt='-10'*/}
                        {/*                ml='-5'*/}
                        {/*                w='12'*/}
                        {/*            >*/}
                        {/*                {tempval}*/}
                        {/*            </SliderMark>*/}
                        {/*            <SliderTrack bg='gray.100'>*/}
                        {/*                <SliderFilledTrack bg='gray.500' />*/}
                        {/*            </SliderTrack>*/}
                        
                        {/*            <SliderThumb boxSize={4} />*/}
                        {/*        </Slider>*/}
                        {/*    </Box>*/}
                        {/*</Stack>*/}

                                <Box ml={5} mt={5}>
                                    <Text fontWeight="bold" mb='8px' mt={2} mr={5}>Engine</Text>
                                    <Select isDisabled={isLoading} onChange={mod => {
                                        mod.target.value !== "" ? setModel(mod.target.value) : setModel("Engine-1")
                                    }} defaultValue="Engine-1" variant="filled"  size="md" w={28} placeholder='Select Engine'>
                                        <option value='Engine-1'>Engine 1</option>
                                        <option value='Engine-2'>Engine 2</option>
                                        <option value='Engine-3'>Engine 3</option>
                                        <option value='Engine-4'>Engine 4</option>
                                        {/*<option value='code-davinci-002'>Code-DaVinci-002</option>*/}
                                        {/*, gpt-3.5-turbo-0301*/}
                                    </Select>
                                </Box>

                        <Center mt={8}>
                            <Button isLoading={isLoading} mb={4} width="xl" onClick={() => queryPrompt()}
                            >Generate Workout</Button>
                        </Center>
                        <Center mt={4}>
                                {/*<Textarea isReadOnly={true} minH={150} mt={6} w="80%" variant={'filled'}  placeholder={"Enter your prompt here..."}>*/}
                                {/*    /!*onChange={(e) => setPrompt(e.target.value)}*!/*/}

                                {/*</Textarea>*/}
                            <SkeletonText minH={150} mt={6} w="80%"   bg={"gray.100"} padding={"3%"} isLoaded={!isLoading} borderRadius="5" noOfLines={10} spacing='4' skeletonHeight='2' >
                                <ReactMarkdown  remarkPlugins={[remarkGfm,]} children={response} />
                            </SkeletonText>

                        </Center>

                    </FormControl>
                </Box>
            </Center>

        </Box>
  );
}

export default App;
